<template>
  <div class="salarySiginPassword">
    <van-nav-bar :title="
        pay_month_title ||
          (isOnlyOne == true || this.isOnlyOne == 'true'
            ? title
            : title + '（' + number + '）')
      "
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <div class="content">
      <div class="userInfoBox">
        <div class="userInfoLeft">
          <van-row v-for="(it, key) in topData"
                   :key="key">
            <van-col span="8">
              <span>{{ it.chnname }}</span>
            </van-col>
            <van-col span="15"
                     offset="1">
              <span>{{ it.fval }}</span>
            </van-col>
          </van-row>
          <!-- <p v-for="(value, key) in topData"
             :key="key">
            <span class="userKey">{{ value.chnname }}</span>
            <span class="userVal">{{ value.fval }}</span>
          </p> -->
        </div>
        <div class="userInfoRight">
          <div class="svg_sigin"
               v-if="fieldobj.is_wxsign">
            <svg-icon icon-class="zu820"></svg-icon>
          </div>
          <div class="default_avator">
            <img :src="fieldobj.empurl"
                 alt="" />
          </div>
        </div>
      </div>
      <div class="userSalaryInfo">
        <van-collapse v-model="activeNames">
          <van-collapse-item :title="item.name"
                             v-for="(item, index) in resData"
                             :key="index"
                             :name="index">
            <div class="salaryContent">
              <van-row v-for="(it, key) in item.data"
                       :key="key">
                <van-col span="8">
                  <span v-if="it.controltype != 'memo'">{{ it.chnname }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.fval }}</span>
                </van-col>
              </van-row>
              <!-- <p v-for="(val, key) in item.data"
                 :key="key">
                <span class="salaryKey"
                      v-if="val.controltype != 'memo'">{{ val.chnname }}</span>
                <span class="salaryVal">{{ val.fval }}</span>
              </p> -->
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
      <div class="userSignBox"
           v-if="fieldobj.url && nosign + '' == 'false'">
        <div class="signTitle">
          <span>{{ $t('salaryDetails.sdTxt1') }}</span>
        </div>
        <div class="imgBox">
          <img :src="fieldobj.url"
               alt="" />
        </div>
      </div>
      <van-overlay :show="show"
                   @click="show = false">
        <div style="width: 100%; height: 100%"
             class="wrapper"
             @click.stop>
          <signaturePad @getSpad="getSpad"
                        @closeSpad="closeSpad"
                        :defaultUrl="lastSignPic"
                        :use_last_sign_pic="use_last_sign_pic"
                        :forbit_edit_last_sign="forbit_edit_last_sign"
                        :show="show"></signaturePad>
        </div>
      </van-overlay>
    </div>
    <div v-if="use_pay_feedback == true || use_pay_feedback == 'true'"
         class="has_feedback">
      <div class="has_feedback_btn1"
           @click="feedback"
           v-if="!fieldobj.is_wxsign">
        {{ $t('salaryDetails.sdTxt5') }}
      </div>
      <div class="has_feedback_btn2"
           @click="SignIn"
           v-if="!fieldobj.is_wxsign && !hide_sign_btn">
        {{ $t('salaryDetails.sdTxt2') }}
      </div>
      <div class="has_feedback_btn2"
           @click="onClickLeft"
           v-if="fieldobj.is_wxsign">
        {{ $t('salaryDetails.sdTxt3') }}
      </div>
    </div>
    <div v-else
         class="footerBox">
      <div class="allSiginButton"
           @click="SignIn"
           v-if="!fieldobj.is_wxsign && !hide_sign_btn">
        {{ $t('salaryDetails.sdTxt2') }}
      </div>
      <div class="allSiginButton"
           @click="onClickLeft"
           v-if="fieldobj.is_wxsign">
        {{ $t('salaryDetails.sdTxt3') }}
      </div>
    </div>

    <!-- // 协议弹窗 -->
    <van-popup v-model="showFeedback"
               class="FeedbackPop"
               round>
      <div class="title">{{ $t('salaryDetails.sdTxt6') }}</div>
      <div class="reason">
        <van-field v-model="feedback_reason"
                   autosize
                   type="textarea"
                   :placeholder="$t('salaryDetails.sdTxt6')" />
      </div>
      <div class="footer">
        <div @click="submitReason">{{ $t('module.submit') }}</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
import { Dialog, Toast, Notify } from 'vant'
import signaturePad from '@components/signaturePad'
import {
  getEmpMonthSalaryDetail,
  signMonthSalarySgl,
  signMonthSalarySglRawJson,
  savePaySignFeedBack,
  getLastPaySignFeedBackInfo,
  openSalaryMonthReport,
  getSalarySignParam,
  getSalaryLastSignPicUrl
} from '@api/wx.js'
export default {
  data () {
    let isOnlyOne = this.$route.query.isOnlyOne
    let firstchangepwd = this.$route.query.firstchangepwd
    let isfirstlogin = this.$route.query.isfirstlogin
    let nosign = this.$route.query.nosign
    let use_pay_feedback = this.$route.query.use_pay_feedback
    let pay_month_title = this.$route.query.pay_month_title
    return {
      pay_month_title,
      use_pay_feedback,
      isOnlyOne,
      firstchangepwd,
      isfirstlogin,
      nosign,
      userInfo,
      show: false,
      activeNames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      yymm: '',
      title: '',
      resData: [],
      fieldobj: {},
      topData: [],
      number: '',
      windowsignremark: window.signremark,
      showFeedback: false,
      feedback_reason: '',
      use_last_sign_pic: false, // 是否启用上次签名
      forbit_edit_last_sign: false, // 上次签名是否可修改
      lastSignPic: '',
      hide_sign_btn: false, // 是否隐藏签收按钮
    }
  },
  components: {
    signaturePad
  },
  watch: {},
  created () {
    this.title = this.$route.query.china
    this.number = this.$route.query.num
    this.getData()
    this.getSalarySetting()
  },
  methods: {
    // 获取上一次工资签收图片地址
    getSalaryLastSignPicUrlData () {
      getSalaryLastSignPicUrl({
        moduleno: this.$route.query.moduleno,
        autoid: this.$route.query.autoid,
        username: this.userInfo.username
      }).then(res => {
        if (typeof res == 'string')
        {
          this.lastSignPic = res
        } else
        {
          this.lastSignPic = ''
        }
      })
    },
    // 获取薪资参数
    getSalarySetting () {
      getSalarySignParam({
        empcode: this.userInfo.empcode
      }).then(res => {
        this.use_last_sign_pic = res.data[0].use_last_sign_pic
        this.forbit_edit_last_sign = res.data[0].forbit_edit_last_sign
        this.hide_sign_btn = res.data[0].hide_sign_btn
        if (this.use_last_sign_pic)
        {
          this.getSalaryLastSignPicUrlData()
        }
      })
    },
    // 反馈意见提交
    submitReason () {
      savePaySignFeedBack({
        autoid: this.$route.query.autoid,
        empcode: this.userInfo.empcode,
        feedback_reason: this.feedback_reason
      }).then(res => {
        if (res.data[0].info)
        {
          Notify({ type: 'danger', message: res.data[0].info })
        } else
        {
          this.showFeedback = false
          Notify({ type: 'success', message: this.$t('module.submitScu') })
        }
      })
    },
    // 反馈意见
    feedback () {
      this.showFeedback = true
      getLastPaySignFeedBackInfo({
        empid: this.userInfo.empid,
        yymm: this.$route.query.yymm
      }).then(res => {
        this.feedback_reason = res.data[0].info
      })
    },
    // 签收按钮
    SignIn () {
      if (this.nosign == true || this.nosign == 'true')
      {
        // 证明不需要签名
        Dialog.confirm({
          title: this.$t('salaryDetails.sdTxt2'),
          message: this.windowsignremark,
          confirmButtonColor: '#2B8DF0'
        })
          .then(() => {
            // on confirm
            this.getSpad(null)
          })
          .catch(() => {
            // on cancel
          })
      } else
      {
        // 要签名
        this.show = true
      }
    },
    onClickLeft () {
      if (this.isOnlyOne == true || this.isOnlyOne == 'true')
      {
        // 如果只有一笔就返回月份列表
        this.$router.push({
          path: '/salarySiginList',
          query: {
            moduleno: this.$route.query.moduleno,
            use_pay_feedback: this.use_pay_feedback,
            firstchangepwd: this.firstchangepwd,
            isfirstlogin: this.isfirstlogin,
            nosign: this.nosign
          }
        })
      } else
      {
        // 多笔就返回笔数列表
        this.$router.push({
          path: '/salaryMonth',
          query: {
            moduleno: this.$route.query.moduleno,
            use_pay_feedback: this.use_pay_feedback,
            yymm: this.$route.query.yymm,
            china: this.$route.query.china,
            firstchangepwd: this.firstchangepwd,
            isfirstlogin: this.isfirstlogin,
            nosign: this.nosign
          }
        })
      }
    },
    onClickRight () { },
    closeSpad () {
      this.show = false
    },
    classifyArr (arr, param) {
      var map = {},
        dest = []
      for (var i = 0; i < arr.length; i++)
      {
        var ai = arr[i]
        if (ai[param] && !map[ai[param]])
        {
          dest.push({ name: ai[param], data: [ai] })
          map[ai[param]] = ai
        } else
        {
          for (var j = 0; j < dest.length; j++)
          {
            var dj = dest[j]
            if (dj.name == ai[param])
            {
              dj.data.push(ai)
              break
            }
          }
        }
      }
      return dest
    },
    getData () {
      // 打开某个月份的工资月报已查阅接口
      openSalaryMonthReport({
        autoid: this.$route.query.autoid,
        empid: this.userInfo.empid
      }).then(res => {
        if (res.data[0].info)
        {
          Toast.fail(res.data[0].info)
        }
      })
      // 查询表单字段详情
      getEmpMonthSalaryDetail({
        autoid: this.$route.query.autoid,
        username: this.userInfo.username
      }).then(res => {
        let newData = []
        let oldData = []
        let fieldlist = res.fieldlist
        this.fieldobj = res.fieldvalue[0]
        for (let i = 0; i < fieldlist.length; i++)
        {
          for (let j in this.fieldobj)
          {
            if (fieldlist[i].fieldname == j)
            {
              fieldlist[i].fval = this.fieldobj[j]
            }
          }
          if (fieldlist[i].iflag == 1)
          {
            newData.push(fieldlist[i])
          } else
          {
            oldData.push(fieldlist[i])
          }
        }
        this.topData = newData
        this.resData = this.classifyArr(oldData, 'groupname')
      })
    },
    getSpad (url) {
      signMonthSalarySglRawJson({
        // signMonthSalarySgl({
        moduleno: this.$route.query.moduleno ? this.$route.query.moduleno : 730,
        autoid: this.$route.query.autoid,
        username: this.userInfo.username,
        filebase64str: url
      }).then(res => {
        console.log(res)
        if (res.iserror == 0)
        {
          Toast(this.$t('salaryDetails.sdTxt4'))
          this.show = false
          this.getData(this.yymm)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.FeedbackPop {
  height: 80%;
  width: 90%;
  .title {
    // color: #fff;
    height: 90px;
    padding: 20px 0;
    text-align: center;
    font-size: 36px;
    font-family: Source Han Sans CN;
  }
  .reason {
    height: calc(100% - 200px);
    padding: 10px 20px;
    .van-field {
      height: 100%;
      border: 1px solid #ebedf0;
      /deep/.van-field__value {
        .van-field__body {
          height: 100%;
          .van-field__control {
            height: 100% !important;
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100px;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 32px;
    display: flex;
    justify-content: center;
    div {
      height: 80px;
      line-height: 80px;
      width: 80%;
      text-align: center;
      background: #2b8df0;
      color: #fff;
      font-size: 36px;
      border-radius: 12px;
    }
  }
}
.salarySiginPassword {
  height: 100%;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .has_feedback {
    height: 132px;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .has_feedback_btn1,
    .has_feedback_btn2 {
      height: 90px;
      text-align: center;
      line-height: 90px;
      color: #fff;
      font-size: 36px;
      border-radius: 12px;
    }
    .has_feedback_btn1 {
      width: 40%;
      background: rgb(255, 174, 201);
    }
    .has_feedback_btn2 {
      width: 40%;
      background: #2b8df0;
    }
  }
  .footerBox {
    height: 132px;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .allSiginButton {
      width: 60%;
      height: 90px;
      text-align: center;
      line-height: 90px;
      background: #2b8df0;
      color: #fff;
      font-size: 36px;
      border-radius: 12px;
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 224px);
    overflow: auto;
    .userInfoBox {
      position: relative;
      width: 100%;
      background: #fff;
      display: flex;
      padding: 0 0.42667rem;
      margin-top: 20px;
      p {
        width: 100%;
        margin-top: 0;
        margin-bottom: 25px;
        height: 40px;
        line-height: 40px;
        display: flex;
        align-items: center;
        .userKey {
          font-size: 32px;
          color: #999;
        }
        .userVal {
          font-size: 32px;
          color: #333;
          margin-left: 42px;
        }
      }
    }
    .userInfoLeft {
      // width: 70%;
      width: 100%;
      min-height: 150px;
      padding-top: 25px;
      .van-row {
        margin-bottom: 30px;
        .van-col:first-child {
          font-size: 32px;
          font-family: Source Han Sans CN;
          color: #999;
        }
        .van-col:last-child {
          font-size: 32px;
          font-family: Source Han Sans CN;
          color: #333;
        }
      }
    }
    .userSalaryInfo {
      margin-bottom: 20px;
    }
    .userInfoRight {
      position: absolute;
      top: 0;
      right: 0.42667rem;
      display: flex;
      align-items: center;
      .svg_sigin {
        width: 130px;
        height: 130px;
        margin-right: 10px;
        .svg-icon {
          width: 100%;
          height: 100%;
        }
      }
      .default_avator {
        width: 160px;
        height: 160px;
        // margin-left: 15px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          // border-radius: 100px;
          border-radius: 50%;
        }
      }
    }
    .userSignBox {
      width: 100%;
      background: #fff;
      margin-top: 20px;
      margin-bottom: 20px;
      .signTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 28px;
        color: #666;
        height: 60px;
        padding-left: 3%;
        padding-right: 4%;
      }
      .imgBox {
        width: 100%;
        background: #fff;
        img {
          margin: 0 auto;
          display: block;
          max-width: 100%;
        }
      }
    }
    .userSalaryInfo {
      /deep/.van-collapse-item {
        margin-top: 20px;
      }
      /deep/.van-cell__title {
        span {
          font-weight: bold;
        }
      }
      /deep/.van-collapse-item__content {
        padding: 0 0.42667rem;
      }
    }
    .salaryContent {
      background: #fff;
      padding: 20px 0 1px 0;
      .van-row {
        margin-bottom: 30px;
        .van-col:first-child {
          font-size: 32px;
          font-family: Source Han Sans CN;
          color: #999;
        }
        .van-col:last-child {
          font-size: 32px;
          font-family: Source Han Sans CN;
          color: #2b8df0;
        }
      }
      p {
        margin: 0;
        margin-bottom: 20px;
        line-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .salaryVal {
        color: #2b8df0;
        text-align: justify;
      }
    }
  }
}
</style>
